<template>
  <b-card-actions
      ref="cardA"
      title="Payment Calendar"
      action-collapse
      no-body
  >
    <b-card class="mb-2" no-body>
      <div class="mx-1">
        <b-row>
          <b-col cols="12">
            <div ref="reportContainer" style="height: 100vh; overflow: hidden;" />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card-actions>
</template>

<script>
import ErrorService from '@/services/ErrorService'
import PowerBIService from '@/services/PowerBIService'
import {
  BCard,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import SweetAlert from '@/services/SweetAlert'

export default {
  components: {
    BCard,
    BCardActions,
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    async doLoadData() {
      this.$refs.cardA.showLoading = true

      const reportName = this.$route.path.replace(/\/powerbi\//, '')
      const { reportContainer } = this.$refs

      try {
        const report = await PowerBIService.generateEmbedReport(reportName, reportContainer)

        report.off('loaded')
        report.on('loaded', () => {})

        report.off('rendered')
        report.on('rendered', () => {})
      } catch (error) {
        await this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })

        if (error.response.status === 401) {
          await this.$router.push({ name: 'heroai-logout' })
        }
      }

      this.$refs.cardA.showLoading = false
    },
  },
}
</script>
